import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

function Football() {
  const shoot = () => {
    alert("Great Shot!");
  }

  return (
    <button className="text-blue-700" onClick={shoot}>Take the shot!</button>
  );
}
const Footy = () => {
  const shoot = () => {
    alert("Fuck Shot!");
  };

  return (
    <button className="text-red-700" onClick={shoot}>Take the shit!</button>
  );
}



function formatName(user) {
  return user.firstName + ' ' + user.lastName;
}

const user = {
  firstName: 'Harper',
  lastName: 'Perez'
};


const element = (
  <h1>
    Hello, {formatName(user)}!
  </h1>
);

function getGreeting(user) {
  if (user.firstName ==='Harper2') {
    return <h1>Hello, {user.firstName}!</h1>;
  }
  return 'Hello, Stranger';
}


const greet = (
  <h3>
    Bye, {getGreeting(user)}!
  </h3>
);

function tick() {
  const element = (
    <div>
      <h1>Hello, world!</h1>
      <h2>It is {new Date().toLocaleTimeString()}.</h2>
    </div>
  );
  ReactDOM.render(element, document.getElementById('root4'));
}
setInterval(tick, 1000);


function age(a) {
  if (a) {
    //return `I am ${a}`
    return 'I am '+a+' years old.'
  }
  return 'I do not have an age'
}
function Welcome(props) {
  return <p className="text-red-600 font-bold">Hello, {props.name}. {age(props.age)}</p>}

const wel = <Welcome name="Sara" age="43" /> //Calls the welcome function above
ReactDOM.render(wel, document.getElementById('root5'));

function App() {
  return (
    <div> {/*This calls the Welcome component (function three times)*/}
      <Welcome name="Lara" age='23' />
      <Welcome name="Cahal" />
      <Welcome name="Edite" />
    </div>
  );
}

ReactDOM.render( //This renders the App function which outputs the Welcome function 3 times
<App />,//This outputs the App function
  document.getElementById('root6')
);




ReactDOM.render(<Football />, document.getElementById('root'))
ReactDOM.render(<Footy />, document.getElementById('root1'))
ReactDOM.render(element, document.getElementById('root2'))
ReactDOM.render(greet, document.getElementById('root3'))
